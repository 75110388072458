/**
 * Etend une zone de clic vers une page de destination
 * la zone d'extension doit porter la classe .extend-click
 * l'url à suivre doit être sur un lien href et porter la classe .extend-url
 */
var extendClickimclicked = false
export function extendClick() {
    $('.extend-click').off('click').on('click', function (e) {
    if ( !$(e.target).is('a') ) { // ne s'applique pas sur le clic d'un a.href sinon clic 2 fois sur le lien sur firefox par exemple et c'est problématique avec des target="_blank"
      if ( !extendClickimclicked ) {
        var a = $(this).find('a.extend-url')
        a.get(0).click()
      }
    }
  })
  $('.extend-click a.extend-url').off('click').on('click', function (e) {
    extendClickimclicked = true
    setTimeout(function() {
      extendClickimclicked = false
    }, 100)
  })
}

// Wait while GMap API is loading
export function dtectMap(action) {
  if ( $('body').hasClass('maploaded') ) {
    //console.log('maploaded')
    action()
  } else {
    setTimeout(function() {
      dtectMap(action)
    }, 200)
  }
}

// Cycling session to keep connection
function keepConnect(action) {
  setTimeout(function() {
    action()
    keepConnect(action)
  }, 60000 * 5) // refresh every 5 minutes
}

/**
 * Teste si une adresse mail est valide
 * @param {string} email
 * @returns {boolean}
 */
function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
  return regex.test(email)
}
