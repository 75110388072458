/* fichier JS de base commun quelques soient les versions du projet web */

// charge bootstrap nécessite jquery et popper
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/util'
// import 'bootstrap'
/* global lazySizes */
import 'lazysizes'

// pas de ./ car on résout dans theme donc déjà déclaré dans resolve.modules dans webpack.base.js
// l'extension n'est pas obligatoire car connue dans resolve.extensions, ne fonctionnera pas si il y a des fichiers de différentes extensions portant le même nom (ex: bootstrap.css et bootstrap.js dans le même dossier)
// import 'bootstrap/scss/bootstrap'
import './scss/_bootstrap_custom'

// Styles custom
import './scss/custom'

// Plugins template
// import 'script-loader!js/plugins.js'

// Scripts librairies
import { handle } from './js/libs/misc'
import { extendClick } from './js/libs/utils'
// import '!./js/components/cookie.js'
// lazy loading https://github.com/aFarkas/lazysizes
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', function (e) {
  const bg = e.target.getAttribute('data-bg')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Simulateur d'extension de maison
function getHeConfigModal () {
  import('./js/components/he-config-modal.js')
}

// Utils
function getScrollToTop () {
  import('./js/libs/scrollToTop/scrollToTop.js').then(() => {
    $.scrollUp({
      easingType: 'linear',
      topSpeed: 300,
      scrollSpeed: 400,
      animation: 'fade',
      scrollText: '<i class="icon-chevron-up inh"></i>'
    })
  })
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  $(window).scroll(function () {
    if ($(window).scrollTop() >= 150) {
      if (!$('#scrollUp').length) getScrollToTop()
    }
  })

  // bouton configurateur extension maison
  $('.heConfigModal').on('click', function () {
    getHeConfigModal()
  })

  // boutons de partage
  $('button[data-toggle="share"]').on('click', function () {
    window.open($(this).attr('data-href'))
  })

  $('.scroll').each(function () {
    const $this = $(this)
    $(this).on('click', function (e) {
      const t = ($(e.target).is('a')) ? this.hash : $(this).attr('target')
      e.preventDefault()
      if ($this.length > 0) {
        $('html, body').animate({
          scrollTop: ($(t).offset().top - 150) - -1
        }, 500)
      }
    })
  })

  handle()
  extendClick()
})
